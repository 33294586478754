const appConfig = {
    apiPrefix: 'https://gateway.motforex.com/api/v1',
    utPrefix: 'https://api.motforex.com/backoffice/v1',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
